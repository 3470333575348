<template>
	<div>
		<a-spin :spinning="loading">
			<a-row>
				<a-col :span="18">
					<a-button @click="onSearchBtn">
						<template #icon><Icon icon="SearchOutlined"/></template>
						查询</a-button>
					<exportModal class="ui-exportBrn" :type="type" :searchData="selIdsData" :condition="condition" :role="role" :isAll="1">
						<a-button>
							<template #icon><Icon icon="UploadOutlined"/></template>
							导出</a-button>
					</exportModal>
					<exportModal class="ui-exportBrn" style="margin-right: 16px;" :type="type" :searchData="searchData" :condition="condition" :role="role" :isAll="0">
						<a-button>
							<template #icon><Icon icon="LogoutOutlined"/></template>
							导出全部</a-button>
					</exportModal>
					<a-button @click="onSearch">
						<template #icon><Icon icon="ReloadOutlined"/></template>
						刷新</a-button>
					<a-button @click="onSort">
						<template #icon><Icon icon="OrderedListOutlined"/></template>
						排序</a-button>
				</a-col>
				<a-col :span="6" style="text-align: right;">
					<a-button v-permission="['pc_inventory_taskManage_release']" type="primary" @click="onPublish">发起任务</a-button>
				</a-col>
			</a-row>
			
			<div v-show="isSearch">
				<a-form class="ui-form" ref="formRef" :model="formModal" layout="inline">
					<a-form-item label="盘点任务名称" class="ui-form__item">
						<a-input v-model:value="formModal.name" ></a-input>
					</a-form-item>
					<a-form-item label="状态" class="ui-form__item">
						<a-select v-model:value="formModal.isFinish"  allow-clear style="width: 190px;">
							<a-select-option :value="1">是</a-select-option>
							<a-select-option :value="0">否</a-select-option>
						</a-select>
					</a-form-item>
					<a-form-item class="ui-form__item">
						<div style="text-align: center;">
							<a-button @click="onReset">重置</a-button>
							<a-button type="primary" @click="onSearch">搜索</a-button>
						</div>
					</a-form-item>
				</a-form>
			</div>
			
			<div v-show="isSort" class="ui-form" style="padding-bottom: 20px;">
				<a-row style="align-items: center;">
					<a-col :span="18">
						<a-checkbox-group v-model:value="sortList">
							<a-checkbox :value="'name'">盘点任务名称</a-checkbox>
							<a-checkbox :value="'expiration_time'">盘点截止时间</a-checkbox>
						</a-checkbox-group>
					</a-col>
					<a-col :span="6" style='text-align: right;'>
						<a-button type="primary" @click="onSortClick('asc')">升序</a-button>
						<a-button type="primary" @click="onSortClick('desc')">降序</a-button>
						<a-button type="primary" @click="onResetSortClick()">重置</a-button>
					</a-col>
				</a-row>
			</div>
			
			<div style="margin-top: 20px;">
				<c-Table ref="cTable" :columns="columns" :isRequest="true" :requestFun="getDataFun" rowSelectionType="checkbox" :searchData="searchData" rowKey="id" @rowSelected="rowSelected" :scroll="{ x: 1500 }" bordered draggable>
					<template #bodyCell="{ column, record, index }">
						<template v-if="column.key === 'index'">
							{{ ((pagination.current - 1) * pagination.pageSize) + index + 1 }}
						</template>
						<template v-if="column.key === 'time'">
							{{ transDateTime(record.expirationTime) }}
						</template>
						<template v-if="column.key === 'informUrl'">
							<a v-if="record.informUrl" :href="record.informUrl">查看资料</a>
							<div v-else>-</div>
						</template>
						<template v-if="column.key === 'isFinish'">
							<div v-if="record.isFinish === 1">是</div>
							<div v-else>否</div>
						</template>
						<template v-if="column.key === 'action'">
							<a-button v-permission="['pc_inventory_dispatch']" type="link" size="small" @click="onProgress(record)">盘点调度</a-button>
						</template>
					</template>
				</c-Table>
				<!-- <a-table rowKey="id" class="table"
					:columns="columns" 
					:dataSource="list" 
					:pagination="pagination" bordered 
					size="small"
					:scroll="{ x: 1500 }"
				>
					<template #bodyCell="{ column, record, index }">
						<template v-if="column.key === 'index'">
							{{ ((pagination.current - 1) * pagination.pageSize) + index + 1 }}
						</template>
						<template v-if="column.key === 'time'">
							{{ transDateTime(record.expirationTime) }}
						</template>
						<template v-if="column.key === 'isComplete'">
							<div v-if="record.status === 1">是</div>
							<div v-else>否</div>
						</template>
						<template v-if="column.key === 'action'">
							<a-button v-permission="['pc_inventory_dispatch']" type="link" size="small" @click="onProgress(record.id)">盘点调度</a-button>
						</template>
					</template>
				</a-table> -->
			</div>
		</a-spin>
		<saveModal ref="saveModalRef" @onReset="onReset"></saveModal>
		<resultModal ref="resultModalRef"></resultModal>
	</div>
</template>

<script>
	import { getSearchList } from '@/service/modules/inventory.js';
	import { Icon } from '@/components/icon/icon.js';
	import saveModal from '@/views/inventoryManage/components/saveModal.vue';
	import resultModal from '@/views/inventoryManage/components/resultModal.vue';
	import cTable from '@/components/cTable/index.vue';
	import exportModal from '@/components/exportModal/exportModal.vue';
	export default {
		components: { Icon, saveModal, resultModal, cTable, exportModal },
		data() {
			return {
				loading: false,
				isSearch: false,
				isSort: false,
				isExpand: false,
				searchData: {},
				formModal: {},
				list: [],
				type: 'inventoryTaskStrategy', // 导出类型
				role: 1, // 导出角色
				condition: '3', // 导出条件
				selIdsData: {},
				sortList:[],
				getDataFun: getSearchList,
				columns: [{
					title: '行号',
					align: 'center',
					key: 'index'
				}, {
					title: '盘点任务名称',
					align: 'center',
					dataIndex: "name"
				}, {
					title: '盘点截止时间',
					align: 'center',
					key: 'time',
					// dataIndex: "expirationTime"
				}, {
					title: '通知文件',
					align: 'center',
					key: 'informUrl',
					dataIndex: "informUrl",
					width: 450
				}, {
					title: '是否已结束',
					align: 'center',
					key: 'isFinish',
					dataIndex: "isFinish"
				}, {
					title: '操作',
					align: 'center',
					key: 'action',
					fixed: 'right',
					width: 120
				}],
				pagination: {
					showSizeChanger: true,
					showQuickJumper: true,
					size: "middle",
					showTotal: (total) => {
						return "共 " + total + " 条";
					},
					total: 0,
					current: 1,
					pageSize: 10,
					onChange: (page) => {
						this.pagination.current = page;
						this.getData();
					},
					onShowSizeChange: (page, pageSize) => {
						this.pagination.current = 1;
						this.pagination.pageSize = pageSize;
						this.getData();
					}
				}
			}
		},
		created() {
			this.onSearch()
		},
		methods: {
			// async getData() {
			// 	this.loading = true;
			// 	try {
			// 		let ret = await getSearchList({
			// 			page: this.pagination.current,
			// 			pageSize: this.pagination.pageSize,
			// 			...this.searchData
			// 		});
			// 		this.loading = false;
			// 		if (ret.code === 200) {
			// 			console.log("inveM",ret)
			// 			this.list = ret.data.list;
			// 			this.pagination.total = ret.data.totalCount;
			// 		}
			// 	} catch(e) {
			// 		this.loading = false;
			// 	}
			// },
			onReset() {
				this.$refs.formRef.resetFields();
				this.formModal = {}
				this.onSearch();
			},
			onSearch() {
				this.searchData = JSON.parse(JSON.stringify(this.formModal));	
				this.pagination.current = 1;
				// this.getData();
				this.$nextTick(() => {
					this.$refs.cTable.toQuery();
				})
			},
			rowSelected(item) {
				this.selIdsData.ids = item.keys.join(',')
			},
			onSearchBtn() {
				this.isSearch = !this.isSearch;
			},
			onSort() {
				this.isSort = !this.isSort;
			},
			onPrint() {
				this.$refs.saveModalRef.open();
			},
			onExport() {
				this.$refs.resultModalRef.open()
			},
			onPublish() {
				this.$refs.saveModalRef.open();
			},
			onProgress(item){ // 携带任务id跳转
				this.$router.push({
					name: 'inventoryManageTaskProgress',
					query: {
						id: item.id,
						taskName: item.name
					}
				})
			},
			onChangeFun(date, dateString) { // 时间变化的回调
			  let starDate = new Date(dateString[0])
			  let endDate = new Date(dateString[1])
			  this.formModal.startTime = starDate.valueOf()/1000
			  this.formModal.endTime = endDate.valueOf()/1000
			}, 
			onSortClick(type){
				let list = [];
				this.sortList.forEach(element => {
					list.push({key:element,sort:type});
				});
				this.formModal.sort = list;
				console.log(this.formModal);
				this.onSearch();
			},
			onResetSortClick(){
				this.sortList = [];
				this.formModal.sort = undefined;
				this.onSearch();
			}
		}
	}
</script>

<style scoped>
	.ui-form {
		margin-top: 20px;
		padding: 20px 20px 0;
		background: #F5F7FA;
	}
	.ui-form__middle {
		padding-top: 4px;
		text-align: center;
	}
	.ui-exportBrn {
		margin-left: 16px;
	}
</style>