<template>
	<div>
		<a-modal v-model:visible="visible" width="750px" @cancel="close">
			<template #title>
				<modalHeader title="盘点任务下达"></modalHeader>
			</template>
			<template #footer>
				<a-button @click="close">取消</a-button>
				<a-button @click="onSubmit" type="primary">下达任务</a-button>
			</template>
			<a-form ref="formRef" :model="formModal" :label-col="{ span: 4 }">
				<a-form-item name="name" :rules="[{ required: true, message: '请输入任务名称' }]" label="盘点任务名称">
					<a-input v-model:value="formModal.name"></a-input>
				</a-form-item>
				
				<a-form-item name="expirationTime" :rules="[{ required: true, message: '请选择时间' }]" label="任务提交时间">
					<a-date-picker  show-time v-model:value="formModal.expirationTime" showTime></a-date-picker>
				</a-form-item>
				
				<a-form-item name="unitIds" label="单位" class="ui-form__item">
					<a-select allowClear showSearch optionFilterProp="label" mode="multiple" v-model:value="formModal.unitIds" placeholder="请选择单位">
						<a-select-option v-for="(item, index) in $store.state.count.unitList" :key="item.id" :value="item.id" :label="item.name">{{ item.name }}</a-select-option>
					</a-select>
				</a-form-item>

				<a-form-item label="通知文件">
					<filesUpload v-model:value="formModal.fileList"></filesUpload>
				</a-form-item>
			</a-form>
		</a-modal>
	</div>
</template>

<script>
	import { assignTask } from '@/service/modules/inventory.js';
	import modalHeader from '@/components/modalHeader/index.vue';
	import filesUpload from '@/components/upload/filesUpload.vue';
	export default {
		components: { modalHeader, filesUpload },
		data(){
			return {
				visible: false,
				formModal: {
					fileList: [],
					unitIds: []
				},
				tempDate: 0,
				fileList: []
			}
		},
		methods: {
			onSubmit() {
				this.$refs.formRef.validate().then(async () => {
					let postData = JSON.parse(JSON.stringify(this.formModal));
					postData.informUrl = this.formModal.fileList.toLocaleString()
					let temp = this.transDateTime(postData.expirationTime)
					let tempTime = new Date(temp)
					postData.expirationTime = tempTime.valueOf()
					postData.expirationTime = this.moment(postData.expirationTime).unix();
					this.loading = true;
					try {
						let ret = await assignTask(postData);
						this.loading = false;
						if (ret.code === 200) {
							this.$message.success("发布成功");
							this.$emit('onReset');
							this.close();
						}
					} catch(e) {
						this.loading = false;
					}
				})
			},
			open() {
				this.visible = true;
			},
			close() {
				this.$refs.formRef.clearValidate();
				this.formModal={
					fileList: []
				},
				this.visible = false;
			}
		},
	}
</script>

<style>
</style>